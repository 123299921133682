.hero {
    width: 100%;
    height: 100vh;
    position: relative;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 42%;
    margin-left: 5rem;
    /* letter-spacing: 3px; */
}

@media(max-width:1200px) {
    .hero-text {
        margin-left: 3.5rem;
    }
}

@media(max-width:992px) {
    .hero-text {
        top: 38%;
    }
}

@media(max-width:576px) {
    .hero-text {
        margin-left: 1.5rem;
        top: 29%;
    }
}

.btn {
    --bs-btn-padding-x: 15px;
}

@media(max-width:991px) {
    .btn {
        --bs-btn-padding-x: 0px;
    }
}