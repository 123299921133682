.navbar-collapse {
    justify-content: end !important;
}

.navbar-fixed {
    position: fixed;
    z-index: 20000;
    backdrop-filter: blur(10px) !important;
    width: 100vw;
}

.dropdown-menu,
.dropdown-toggle {
    background: transparent !important;
    border-color: transparent !important;
}

.dropdown-menu {

    --bs-dropdown-min-width: 8.5rem !important;
}

.dropdown-item {
    color: white !important;
}

.dropdown-item:hover {
    color: inherit;
    background-color: transparent !important;
}

.btn-social {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: 5px;
    text-align: center;
    text-decoration: none !important;
    border: 1px solid #ffffff2f;
    font-size: x-large;
}

.text-navcenter {
    justify-content: center !important;
}

@media (max-width:1199px) {
    .nav-icon {
        display: none;
    }
}

@media (max-width:1399px) {
    .navbar-toggler {
        border: transparent !important;
    }
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.logo-style {
    width: 300px !important;
    margin-top: 1rem;
    /* margin-left: 3.5rem !important; */
}

@media (max-width:1200px) {
    .logo-style {
        width: 250px !important;
        margin-left: 2rem !important;
    }
}

@media (max-width:1074px) {
    .logo-style {
        width: 200px !important;
    }
}

@media (max-width:1023px) {
    .logo-style {
        width: 170px !important;
    }
}

@media(max-width:576px) {
    .logo-style {
        margin-left: 0rem !important;
        width: 200px !important;
    }
}

.mt-footer {
    margin-top: 200px;
}

.navbar-toggler-icon {
    background-image: url("../../src/assets/logo-icon.png");
}

.custom-dropdow .dropdown-menu {
    background-color: rgba(255, 255, 255, 0.147) !important;
    backdrop-filter: blur(100rem) !important;
    border-radius: 0px;
    top: 66px;
    --bs-dropdown-padding-y: 0px !important;
    margin-right: 60px;
    --bs-dropdown-spacer: 0rem;
    --bs-dropdown-border-width: none !important;
}

@media(max-width:992px) {
    .custom-dropdow .dropdown-menu {
        --bs-dropdown-min-width: 100% !important;
        background-color: transparent !important;
        --bs-dropdown-item-padding-x: 0.25rem;
        margin: 0px;
        padding: 0px;
        text-align: start !important;
    }
}


.facebook-icon-mobile {
    font-size: 25px;
    color: #fff;
    padding: 0.5rem;
    border: 1px solid #ffffff2f;
    border-radius: 50%;
    margin-right: 0.15rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.instagram-icon-mobile {
    /* font-size: 25px; */
    width: 25px;
    height: 25px;
    color: #fff;
    padding: 0.5rem;
    border: 1px solid #ffffff2f;
    border-radius: 50%;
    margin-left: 0.15rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

@media(max-width:1399px) {
    .home-top-mobile {
        margin-top: 1rem;
    }
}

@media(min-width:1225px) and (max-width:1336px) {
    .nav-logo-lg {
        max-width: 250px !important;
    }
}

@media(min-width:1200px) and (max-width:1224px) {
    .nav-logo-lg {
        max-width: 225px !important;
    }
}

@media(min-width:1200px) and (max-width:1250px) {
    .btn-social {
        width: 40px;
        height: 40px;
        font-size: 23px;
    }
}