.footer-title {
    color: #FFF;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px;
}

/* @media(min-width:593px){
    .mobilescreen{
        
    }
} */

.footer-layout {
    /* width: 1200px; */
    height: 0.5px;
    background: rgba(255, 255, 255, 0.423);
}

.footer-facebook-icon {
    width: 30px !important;
    height: 29px !important;
    flex-shrink: 0;
}

.footer-align-end {
    text-align: end !important;
    margin-right: 1rem;
}

.footer-align-start {
    text-align: start !important;
    margin-left: 1rem;
}

.footer-font {
    /* color: #FFF;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px; */
    color: rgba(255, 255, 255, 0.60);
    ;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 3.2px;
}

.higher-font {
    color: rgba(255, 255, 255, 0.60);
    text-align: end;
    font-family: Urbanist;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 3.2px;
    margin-top: 1rem;
}

@media(max-width:690px) {
    .higher-font {
        text-align: center;
    }
}

@media(max-width:992px) {
    .higher-not-desktop {
        display: none;
    }
}

@media(min-width:993px) and (max-width:2160px) {
    .higher-not-mobile {
        display: none;
    }
}