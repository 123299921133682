@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100&display=swap');

/* .row {
    padding-right: 0px !important;
    padding-left: 0px !important;
} */

.services-text {
    font-family: 'Urbanist';
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 4px;
    margin: 1rem;
    padding: 1rem;
}

/* @media(max-width:992px) {
    .services-text {
        font-weight: 400;
    }
} */

@media(max-width:1306px) {
    .services-text {
        margin: 0px;
        padding: 0rem;
        margin-bottom: 3rem;
    }
}

.services-line {
    width: 0.25px;
    height: 120px;
    background: rgba(255, 255, 255, 0.60);
    margin-top: 10px;
    border-radius: 50px;
}

@media(max-width:1306px) {
    .services-line {
        margin: 0px;
        padding: 0rem;
        margin-bottom: 3rem;
    }
}

.width-100 {
    width: 0% !important;
}

/* .margin-7 {
    padding: 3rem;
} */

.width-fijo {
    width: 350px;
}

@media(max-width:515px) {
    .width-fijo {
        width: 240px;
    }
}

