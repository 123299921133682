.float {
    position: fixed;
    width: 80px;
    height: 80px;
    bottom: 60px;
    right: 100px;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
}

@media(max-width:992px){
    .float {
        width: 60px;
        height: 60px;
        right: 25px;
    }
}