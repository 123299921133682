.portada-title {
    color: #FFF;
    font-family: Urbanist;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 10px;
}

@media (max-width:991px) {
    .portada-title {
        font-size: 30px;
    }
}

.portada-img {
    /* width: 719px; */
    height: 660px;
    flex-shrink: 0;
}

@media (max-width:991px) {
    .portada-img {
        margin-left: 0rem !important;
        height: 300px;
    }
}

.portada-img1 {
    /* width: 421px; */
    height: 940px;
    flex-shrink: 0;
    margin-left: 2rem;
}

@media (max-width:991px) {
    .portada-img1 {
        margin-left: 0rem !important;
        height: 550px;
    }
}

.portada-img2 {
    /* width: 719px; */
    height: 232px;
    flex-shrink: 0;
}

@media (max-width:991px) {
    .portada-img2 {
        margin-left: 0rem !important;
        height: 225px;
    }
}

