.contact-mt {
    margin-top: 13rem;
}

@media(max-width:992px) {
    .contact-mt {
        margin-top: 9rem;
    }
}

.contact-ml {
    margin-left: 6.5rem;
}

@media(max-width:700px) {
    .contact-ml {
        margin-left: 200px;
    }
}

.contact-title {
    color: rgba(255, 255, 255, 0.80);
    font-family: Urbanist;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contact-text {
    text-align: start;
    margin-top: 1rem;
    width: 530px;
}

.contact-text-2 {
    text-align: end;
    /* margin-left: 20px; */
    padding-top: 0rem;
    border-radius: 20px;
    border-color: red;
    padding-right: 1rem !important;
    margin-top: 10px;
}

@media(max-width:1500px) {
    .contact-text-2 {
        margin-left: -2rem !important;
    }
}

@media(max-width:1345px) {
    .contact-text-2 {
        margin-left: 0rem !important;
    }
}


@media(max-width:1200px) {
    .contact-text-2 {
        margin-left: -1rem !important;
    }
}

@media(max-width:992px) {
    .contact-text-2 {
        margin-top: 1rem;
        padding-right: 0px !important;
    }
}

@media(max-width:1200px) {
    .contact-ml {
        margin-left: 0rem !important;
    }
}

@media(max-width:700px) {
    .contact-ml {
        margin-left: 0rem !important;
    }
}

.form-control {
    border: 0.25px solid rgba(255, 255, 255, 0.30);
    background: transparent;
    color: white;
    border-radius: 0px;
    margin-bottom: 1rem;
    /* width: 602px; */
    height: 47px;
    flex-shrink: 0;
}

.text-area {
    height: 280px;
}

.contact-button {
    border-radius: 35px;
    background: #121111;
    height: 48px;
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.6px;
    border-color: transparent !important;
    border: 0.5px solid rgba(255, 255, 255, 0.30);
}

input {
    background-color: transparent !important;
}

textarea {
    background-color: transparent !important;
}

textarea::placeholder {
    color: rgba(255, 255, 255, 0.643) !important;
    letter-spacing: 2px;
    font-weight: 100;
    font-size: 16px;
}

.form-control {
    color: #fff !important;
    --webkit-appearance: none;
}


.form-control:focus {
    border-color: #fff;
    box-shadow: none !important;
}

input::placeholder {
    color: rgba(255, 255, 255, 0.643) !important;
    letter-spacing: 2px;
    font-weight: 100;
    font-size: 16px;
}

.map-style {
    opacity: 75%;
    width: 530px;
    height: 563px;
}

.p-map {
    padding-left: 0px;
    padding-right: 0px !important;
}

.mt-map {
    margin-top: 13rem;
}

@media(max-width:1345px) {
    .mt-map {
        margin-top: 16.5rem;
        padding-left: 2rem;
    }
}

@media(max-width:992px) {
    .mt-map {
        padding-left: 0px !important;
        margin-top: 6rem;
    }
}

@media(max-width:1500px) {
    .map-style {
        width: 400px;
    }
}

@media(max-width:991px) {
    .map-style {
        width: 100%;
        /* margin: 2rem; */
    }
}

@media(max-width:767px) {
    .map-style {
        width: 103%;
    }
}

.row {
    padding-right: 0px !important;
    padding-left: 0.5rem !important;
}

.p-form {
    padding-left: 0px !important;
}

.a-no-deco {
    text-decoration: none;
}