.mediosdepago-style {
    /* border: 0.5px solid rgba(255, 255, 255, 0.50); */
    border: 0.25px solid rgba(255, 255, 255, 0.30);
    /* width: 1150px; */
    /* height: 500px; */
}

.mediosdepago-title {
    color: #FFF;
    font-family: Urbanist;
    font-size: 41px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 8.2px;
    margin-bottom: 4rem !important;
}

@media(max-width:900px) {
    .mediosdepago-title {
        font-size: 30px;
        margin-bottom: 5rem !important;
    }
}

.logo-mercadopago {
    width: 278px;
    flex-shrink: 0;
}

.logo-visa {
    width: 196px;
    flex-shrink: 0;
}

@media(max-width:833px) {
    .logo-visa {
        width: 141px;
    }
}

.logo-mastercard {
    width: 137px;
    flex-shrink: 0;
}

.logo-americanexpress {
    width: 242px;
    flex-shrink: 0;
}

@media(max-width:833px) {
    .logo-americanexpress {
        width: 282px;
    }
}

.medios-mt {
    margin-top: 9rem;
}

.medios-center {
    height: 33% !important;
    align-items: center;
}

.pagos-text {
    color: #FFF;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 3.2px;
    margin-bottom: 3rem;
}

.pr-medios {
    padding-left: 0rem !important;
}

.no-row {
    padding-left: 0rem !important;
}

.row {
    padding-left: 0px !important;
}