.categorys {
    text-transform: uppercase !important;
    letter-spacing: 5px;
    size: 18px;
    font-weight: 300;
}

.container-products {
    padding-top: 10rem;
    /* Ajusta este valor según la altura de tu barra de navegación */
}

.container-img {
    display: flex;
    flex-direction: row-reverse;
}

.img-style-product {
    width: 100%;
    height: 100%;
}

.title {
    font-size: 50px;
    font-weight: 400;
}

.description {
    border: 0.5px solid rgba(255, 255, 255, 0.267);
}

.description-title {
    font-size: 25px;
}

.description-text {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 275;
    line-height: 30px;
    /* 187.5% */
    width: 335px;
}

@media(max-width:1200px) {
    .description-text {
        width: 250px;
    }
}


@media(max-width:768px) {
    .description-text {
        width: 80%;
    }
}

/* @media(max-width:465px) {
    .description-text {
        width: 250px;
    }
} */


@media(min-width:1200px) {
    .img-product {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        padding-bottom: 2rem;
    }
}

/* @media(max-width:992px){
    .img-product {
        padding-left: 1rempx;
        padding-right: 1rem;
        padding-bottom: 0px;
    }
} */

.caract-product {
    width: 200px;
}

.m-leftproduct {
    margin-left: 3rem;
}

@media(max-width:1200px) {
    .m-leftproduct {
        margin-left: 0px !important;
    }
}

    .category-text {
        margin-bottom: 0 !important;
    }

.row-not {
    padding-left: 0px !important;
}