.container-nosotros {
    padding-top: 13rem !important;
    /* Ajusta este valor según la altura de tu barra de navegación */
}

@media(max-width:992px) {
    .container-nosotros {
        /* margin-left: 1rem !important; */
        padding-top: 9rem !important;
    }
}

.title-nosotros {
    font-size: 50px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
}

.spicing {
    letter-spacing: 2px;
}

.texto-nosotros {
    margin-top: 5rem;
    margin-bottom: 6rem;
    color: #FFF;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 4px;
    width: 550px;
}

@media(max-width:1200px) {
    .texto-nosotros {
        width: 410px;
    }
}

@media(max-width:992px) {
    .texto-nosotros {
        width: 100%;
        margin-bottom: 3rem;
    }
}

.img-style {
    width: 499px;
    height: 240px;
    margin-bottom: 2rem;
}

@media(max-width:992px) {
    .img-style {
        width: 100% !important;
        padding: 1rem 0.5rem;
        margin-bottom: 0px !important;
        /* margin-left: 1rem !important; */
        /* margin-right: 2rem !important; */
    }
}

.line-nosotros {
    width: 345px;
    height: 0.5px;
    background: rgba(255, 255, 255, 0.50);
    margin-top: 4rem;
}

.firma-nosotros {
    font-weight: 800;
}

@media(max-width:992px) {
    .firma-nosotros {
        width: 100%;
    }
}

.img-nosotros {
    padding-top: 13rem;
}

@media(max-width:992px) {
    .img-nosotros {
        padding-top: 3rem;
    }
}